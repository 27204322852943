<template>
  <div>
    <NewMenu />
    <div class="container">
      <section class="__section">
        <h1 class="mt-4 mt-md-0 mb-4">{{ data.title }}</h1>
        <div class="container">
          <form
            class="w-100 h-100 needs-validation is-valid"
            @submit="checkForm"
          >
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="form-group wet-form">
                  <label class="form-label">{{
                    data.fields.document.text
                  }}</label>
                  <input
                    v-model="Form.document"
                    :placeholder="data.fields.document.placeholder"
                    type="text"
                    class="form-control"
                    required
                  />
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group wet-form">
                  <label class="form-label">{{
                    data.fields.website.text
                  }}</label>
                  <input
                    v-model="Form.website"
                    :placeholder="data.fields.website.placeholder"
                    type="text"
                    class="form-control"
                    required
                  />
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group wet-form">
                  <label class="form-label">{{ data.fields.email.text }}</label>
                  <input
                    v-model="Form.contactEmail"
                    :placeholder="data.fields.email.placeholder"
                    type="email"
                    class="form-control"
                    required
                  />
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group wet-form">
                  <label class="form-label">{{ data.fields.name.text }}</label>
                  <input
                    v-model="Form.contactName"
                    :placeholder="data.fields.name.placeholder"
                    type="text"
                    class="form-control"
                    required
                  />
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group wet-form mb-4 password">
                  <label class="form-label">{{
                    data.fields.password.text
                  }}</label>
                  <input
                    v-model="Form.password"
                    :placeholder="data.fields.password.placeholder"
                    :type="showPassword ? 'password' : 'text'"
                    class="form-control"
                    required
                  />
                  <img
                    :src="
                      showPassword
                        ? require('../../assets/icons/hide.png')
                        : require('../../assets/icons/show.png')
                    "
                    class="icon"
                    width="20px"
                    alt=""
                    @click="showPassword = !showPassword"
                  />
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group wet-form mb-4 password">
                  <label class="form-label">{{
                    data.fields.repeatedPassword.text
                  }}</label>
                  <input
                    :placeholder="data.fields.repeatedPassword.placeholder"
                    @input="handleRepeatedPass($event.target.value)"
                    :type="showRepeatedPassword ? 'password' : 'text'"
                    class="form-control"
                    required
                  />
                  <img
                    :src="
                      showRepeatedPassword
                        ? require('../../assets/icons/hide.png')
                        : require('../../assets/icons/show.png')
                    "
                    class="icon"
                    width="20px"
                    alt=""
                    @click="showRepeatedPassword = !showRepeatedPassword"
                  />
                </div>
                <p
                  v-show="Form.password !== repeatedPass"
                  style="
                    color: red;
                    margin-top: -1rem;
                    font-size: 12px !important;
                  "
                  class="fw-bold"
                >
                  Las contrasenas no coinciden!
                </p>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group wet-form">
                  <label class="form-label">{{ data.fields.phone.text }}</label>
                  <vue-phone-number-input
                    v-model="Form.phone"
                    @update="handleInput"
                    v-bind="vuePhone.props"
                  ></vue-phone-number-input>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group wet-form">
                  <label class="form-label">{{
                    data.fields.companyAge.text
                  }}</label>
                  <input
                    v-model="Form.operationsYear"
                    :placeholder="data.fields.companyAge.placeholder"
                    class="form-control"
                    type="number"
                    required
                  />
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group wet-form">
                  <label class="form-label">{{
                    data.fields.company.text
                  }}</label>
                  <input
                    v-model="Form.companyName"
                    :placeholder="data.fields.company.placeholder"
                    class="form-control"
                    type="text"
                    required
                  />
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group wet-form">
                  <label class="form-label">{{
                    data.fields.country.text
                  }}</label>
                  <div class="d-flex">
                    <country-flag
                      class="mx-2"
                      v-if="Form.companyCountry"
                      :country="Form.companyCountry.code"
                      size="normal"
                    />
                    <select
                      class="form-select"
                      name="country-select"
                      required
                      id="countrySelect"
                      v-model="Form.companyCountry"
                    >
                      <option
                        v-for="country in countriesList"
                        :key="country.code"
                        :value="country"
                      >
                        {{ country.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-12"></div>
            </div>
            <div class="form-group">
              <button
                class="btn btn-success btn-lg"
                :disabled="validationPass"
                type="submit"
              >
                {{ data.actionButtonText }}
              </button>
            </div>
          </form>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import NewMenu from "@/components/layout/NewMenu.vue";
import { countriesList } from "../../utils/countries";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import Vue from "vue";
import { registerUser } from "../../helpers/auth";
import store from "../../store";
import CountryFlag from "vue-country-flag";

Vue.component("vue-phone-number-input", VuePhoneNumberInput);
Vue.component("country-flag", CountryFlag);

export default {
  components: { NewMenu },
  beforeDestroy() {},

  created() {},

  data() {
    return {
      countriesList: countriesList,
      repeatedPass: null,
      data: {
        title: "Registrarme",
        actionButtonText: "Guardar",
        formattedNumber: "",
        fields: {
          document: {
            text: "Rut o DNI empresa (sin puntos ni Guión)",
            placeholder: "Ingrese su numero de documento",
          },
          name: {
            text: "Nombre de contacto",
            placeholder: "Ingrese nombre de contacto",
          },
          company: {
            text: "Nombre de la empresa",
            placeholder: "Ingrese nombre de la empresa",
          },
          email: {
            text: "Correo",
            placeholder: "Ingrese un email de contacto",
          },
          companyAge: {
            text: "En que año inició operaciones la empresa",
            placeholder: "Ingrese el año de operaciones",
          },
          phone: { text: "Número de télefono" },
          website: { text: "Sitio Web", placeholder: "Ingrese sitio web" },
          country: {
            text: "¿En qué país tiene su sede la empresa?",
            placeholder: "Indicar país ",
          },
          city: { text: "Ciudad", placeholder: "Ingrese ciudad" },
          password: { text: "Contraseña", placeholder: "Ingresar contraseña" },
          repeatedPassword: {
            text: "Repetir Contraseña",
            placeholder: "Repita su contraseña",
          },
        },
      },
      showPassword: true,
      showRepeatedPassword: true,
      vuePhone: {
        props: {
          color: "#212529",
          "valid-color": "#23af4d",
          required: true,
          clearable: false,
          fetchCountry: true,
          preferredCountries: ["CL"],
          noExample: false,
          translations: {
            countrySelectorLabel: "Codigo de país",
            countrySelectorError: "Error",
            phoneNumberLabel: "Ingrese su número",
            example: "Ejemplo:",
          },
        },
      },
      Form: {
        contactName: null,
        contactEmail: null,
        companyName: null,
        companyCountry: null,
        document: null,
        password: null,
        phone: null,
        operationsYear: null,
        website: null,
      },
    };
  },
  computed: {
    validationPass() {
      return (
        this.Form.password !== this.repeatedPass ||
        Object.values(this.Form).some((x) => x === null)
      );
    },
  },
  mounted() {
    this.fetchData();
  },
  watch: {
    "$store.state.sections": function () {
      this.fetchData();
    },
  },
  methods: {
    handleInput(e) {
      this.formattedNumber = e.formattedNumber;
    },
    handleRepeatedPass(value) {
      this.repeatedPass = value;
    },
    fetchData() {
      if (store.state.sections.register) {
        this.data = { ...store.state.sections.register.components };
      }
    },
    async checkForm(e) {
      this.$store.commit("ActivaLoader", true);
      e.preventDefault();
      const response = await registerUser({
        ...this.Form,
        companyCountry: this.Form.companyCountry.name,
        language: store.getters.getLanguage,
        phone: this.formattedNumber,
      });
      if (response.status === 200) {
        this.$router.push("/login");
      }

      this.$store.commit("ActivaLoader", false);
    },
  },
};
</script>
<style lang="scss" scoped>
.btn-success {
  width: 100%;
  margin: 55px 0 15px 0;
}

.password {
  position: relative;

  & .icon {
    cursor: pointer;
    position: absolute;
    top: 60%;
    right: 3%;
  }
}

h1 {
  font-size: 3rem !important;
  color: #23af4d;
  font-weight: bold;
}

.form-check-label {
  font-weight: 400 !important;
}

@media (min-width: 1200px) {
  .wet-module-container {
    justify-content: center;
  }
}
</style>
